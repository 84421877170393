import { atom, useSetAtom } from 'jotai';
import React from 'react';

export const baseApiURL = process.env.NEXT_PUBLIC_EDM_BASE_API_URL ?? '';
export const apiURL = atom<string>(
	process.env.NEXT_PUBLIC_EDM_BASE_API_URL ?? ''
);
export const nextAuth = atom<string>(process.env.NEXTAUTH_URL ?? '');

export const useEnvironment = () => {
	const setApiUrl = useSetAtom(apiURL);
	const setNextAuth = useSetAtom(nextAuth);

	React.useEffect(() => {
		setApiUrl(
			process.env.NEXT_PUBLIC_EDM_BASE_API_URL ??
				window.process.env.NEXT_PUBLIC_EDM_BASE_API_URL ??
				''
		);
		setNextAuth(
			process.env.NEXTAUTH_URL ?? window.process.env.NEXTAUTH_URL ?? ''
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
