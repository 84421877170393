import * as React from 'react';

import { useBaseUrlAxios } from '@/http/api-client';

const Environment: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [baseUrlSet, setBaseUrlSet] = React.useState(false);

	useBaseUrlAxios();

	React.useEffect(() => {
		// Mark that the base URL has been set
		setBaseUrlSet(true);
	}, []);

	return <>{baseUrlSet && children}</>;
};
export default Environment;
